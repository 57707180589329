import Helmet from "react-helmet";
import React from "react";

const SEO = ({ description, title }) => (
  <Helmet>
    <html lang="en" />
    <title>{ title }</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta charSet="utf-8" />
    <meta name="description" content={description} />
  </Helmet>
);

export default SEO;
